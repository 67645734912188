@import "./src/styles/lib";

.Footer {
  width: 100%;
  background-color: #fbfbfb;
  padding: 40px 0 0 0;

  &__desktop {
    display: flex;
    background-color: #252525;
    padding: 60px 30px 60px 30px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;

    &_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @include mobile {
        justify-content: center;
      }
    }
    &_element {
      display: flex;
      align-items: center;
      @include mobile {
        margin-bottom: 50px;
        flex-direction: column;
      }
      &-icon {
        display: flex;
        border: 2px solid $primary;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        @include mobile {
          margin-bottom: 10px;
        }

        svg {
          fill: $primary;
          width: 35px;
          height: 28px;
        }
      }
      &-text {
        @include mobile {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        h1 {
          font-size: 20px;
          font-weight: 700;
          color: $primary;
          span {
            color: white;
          }
        }
        > span {
          font-size: 14px;
          font-weight: 300;
          color: white;
        }
      }
    }
  }

  &__list {
    &-head {
      text-transform: uppercase;
      font-weight: 600;
      color: black;
      margin-bottom: 35px !important;
    }
    li {
      margin-bottom: 20px;
    }
    span {
      color: #626262 !important;
      transition: all 0.2s;
      &:hover {
        color: $primary !important;
        cursor: pointer;
      }
    }

    a {
      color: #626262 !important;
      &:hover {
        color: $primary !important;
      }
    }

    &-phone,
    &-location {
      display: flex;
      align-items: center;
      svg {
        margin-right: 20px;
      }
    }
    &-phone {
      a {
        font-size: 22.4px;
        font-weight: bold;
        line-height: 20px;
        color: $primary !important;
      }
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 4.235801032em;
    @include mobile {
      flex-direction: column;
    }
    &__right {
      width: 65%;
      @include mobile {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
    &--text {
      width: 35%;
      @include mobile {
        width: 100%;
      }
    }
  }

  &__copyright {
    color: #888888;
    background-color: #252525;
    padding: 1.618em 0;
    margin-top: 15px;

    span {
      display: block;
      text-align: center;
      font-size: 14px;
      line-height: 35px;

      @include mobile {
        font-size: 12px;
      }
    }
  }
}
