@import "src/styles/lib";

.Campaigns {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 2fr);

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  @include tablet {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  &-item {
    min-height: 650px;
    position: relative;
    overflow: hidden;
    height: 650px;

    @include mobile {
      min-height: 460px;
      margin-bottom: 20px;
      img {
        min-height: 500px;
        object-fit: cover;
      }
    }

    @include tablet {
      height: 460px;
      margin-bottom: 20px;
      img {
        min-height: 500px;
        object-fit: cover;
      }
    }

    img {
      transition: 0.5s;
      width: 100%;
      height: 100%;
    }

    &__text-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 20px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      p {
        font-weight: 500;
        font-size: 21px;
        line-height: 1.5;
        margin-bottom: 4px;
        color: white;
        margin-bottom: 20px;
      }
      &-btn {
        display: flex;
        width: fit-content;
        background-color: white;
        padding: 8px 28px;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 3px;
        background: #fff;
        color: #111;
        &:hover {
          background-color: rgba(255, 255, 255, 0.7);
          border-color: rgba(255, 255, 255, 0.7);
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  @include tablet {
    flex-direction: column;
  }
  &_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    h2 {
      margin-bottom: 10px;
    }
  }
}
