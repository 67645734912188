@import "src/styles/lib";

.ProductSingleCarousel {
  position: relative;
  min-height: 100%;
  max-height: 520px;

  &--out_of_stock {
    .ProductSingleCarousel {
      &__thumbs {
        bottom: 90px;
      }
    }
  }
  &__image {
    width: 520px;
    overflow: hidden;
    position: relative;
    min-height: 100%;
    max-height: 580px;
    padding: 5px;
    border: 1px solid #eeeeee;
    &_wrapper {
      display: flex;
      padding-left: 110px;

      @include mobile {
        padding-left: 0;
      }
    }
    .slick-list {
      height: 468px;
    }
    @include tablet {
      width: 100%;
    }
  }

  &__item {
    &:hover {
      cursor: zoom-in;
    }
    img {
      object-fit: cover;
      max-height: 515px;
      @include mobile {
        height: 480px;
        max-height: 480px;
        object-fit: cover;
      }

      @include tablet {
        height: 480px;
        max-height: 480px;
        object-fit: cover;
      }
    }
  }

  &__no-stock {
    top: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: flex-end;
    span {
      display: flex;
      align-items: center;
      width: 100px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 5px;
      border-radius: 3px;
      text-transform: lowercase;
      background-color: $primary;
      position: absolute;
      top: 40px;
      right: 0;
      transform: rotate(-45deg);
    }
  }

  @include tablet {
    width: auto;
    max-height: 480px;
    margin-left: -15px;
    margin-right: -15px;
  }

  @include mobile {
    width: auto;
    max-height: 480px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .slick-slide,
  .slick-track {
    :focus {
      outline: none !important;
    }

    outline: none !important;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__thumbs {
    z-index: 3;
    left: 25px;
    top: 0;
    position: absolute !important;
    transition: all 0.3s ease-in-out;
    width: 71px;
    .slick-list {
      // height: 47px;
      width: 71px;
      overflow: hidden;
    }
    .slick-track {
      // float: left;
      display: flex;
      flex-direction: column;
      width: 71px !important;
      @include tablet {
        overflow: scroll !important;
        max-height: 468px !important;
        scrollbar-width: none;
      }
    }
    .slick-slide {
      height: fit-content;
      width: 71px;
    }

    @include tablet {
      left: 20px;
      bottom: 0;
      top: unset;
    }

    &__item {
      border: 1px $gray solid;
      width: auto !important;
      width: 60px !important;
      height: 60px !important;
      cursor: pointer;
      margin-bottom: 7px !important;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .slick-current {
      .ProductSingleCarousel__thumbs__item {
        border: 1px solid $primary;
      }
    }
  }
}

.ProductSingleCarouselLoader {
  @include tablet {
    width: 100%;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: Block;
    }
  }
  @include mobile {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
  }
}
