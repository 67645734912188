@import "./../../../styles/_lib.scss";

.OrderDetails {
  border-bottom: 1px solid hsla(0, 0%, 67.8%, 0.3);
  padding: 20px 0;
  &__table {
    width: 100%;
    display: flex;
    // margin-bottom: 30px;
    flex-direction: column;

    @include mobile {
      margin-bottom: 15px;
    }

    &__title {
      display: block;
      font-size: 13px;
      text-align: left;
      font-weight: 700;
      line-height: 1.3em;
      padding-left: 0;
      margin-bottom: 10px;

      color: #000;
      @include mobile {
        margin-bottom: 2px;
      }
    }
    .CartSidebar-list-item {
      margin-bottom: 10px;
      padding: 10px 0;
      background-color: #fbfbfb;
      border-bottom: 1px solid rgba(173, 173, 173, 0.3);
      &:last-child {
        border-bottom: 0;
      }
    }
    // .CartSidebar-list {
    //   // overflow-y: scroll;
    //   // height: 200px;
    //   // /* Hide scrollbar for Chrome, Safari and Opera */
    //   // &::-webkit-scrollbar {
    //   //   display: none;
    //   // }

    //   // /* Hide scrollbar for IE, Edge and Firefox */
    //   // -ms-overflow-style: none; /* IE and Edge */
    //   // scrollbar-width: none; /* Firefox */
    // }
  }
  &__total {
    margin: 20px 0;
    display: flex;
    width: 100%;

    // @include mobile {
    //   grid-gap: 15px;
    //   grid-template-columns: repeat(1, 4fr);
    // }
    // @include tablet {
    //   grid-gap: 15px;
    //   grid-template-columns: repeat(1, 4fr);
    // }

    &__price {
      height: 44px;
      display: flex;
      padding: 0 20px;
      background: #fff;
      border: 1px solid $primary;
      align-items: center;
      width: 100%;
      strong {
        color: $black;
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.3em;

        text-transform: uppercase;

        &:last-child {
          font-size: 18px;
          margin-left: auto;
        }
      }

      span {
        color: $black;
        display: block;
        font-size: 18px;
        line-height: 1.3em;

        text-transform: uppercase;

        &:last-child {
          font-size: 18px;
          margin-left: auto;
          text-transform: capitalize;
        }
      }
    }
  }
  &__table {
    &__thead {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 3fr);
    }
    &__item {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 3fr);
    }
  }
}
