@import "src/styles/lib";

.ShopSelect {
  position: relative;

  &--has-selected {
    .ShopSelect__label {
      font-weight: 700;
    }
  }

  &--relative {
    .ShopSelect__dropdown {
      position: relative;
    }
  }

  &--relative-icon {
    .ShopSelect__label_icon {
      position: relative;
      margin-left: 40px;
    }
  }

  &__label {
    position: relative;
    width: 100%;

    font-size: 14px;
    line-height: em(20px, 14px);
    color: #000000;
    font-weight: 500;
    text-align: left;

    background-color: transparent;

    //border: 1px solid lighten($black, 85%);
    border-radius: 3px;
    padding: 7px 15px;

    &:hover {
      color: lighten($black, 40%);
      .ShopSelect__label_icon_arrow {
        fill: lighten($black, 40%);
      }
    }

    &[data-opened="true"] {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &__label_selected {
    font-size: 11px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
  }

  .Icon {
    //display: none !important;
    width: 18px;
    height: 18px;
  }

  &__label_icon {
    position: absolute;

    display: none;
    right: 4px !important;

    &_spinner {
      position: relative;
      top: 2px;
      width: 12px !important;
      height: 12px !important;
      border: 0.2em solid #000000 !important;
      border-right-color: transparent !important;
      vertical-align: unset !important;
    }

    &_arrow {
      transition: 0.3s;
      fill: rgba(0, 0, 0, 1);
      opacity: 0.4;
    }

    &[data-opened="true"] {
      .ShopSelect__label_icon_arrow {
        fill: #000000;
        transform: rotate(180deg);
      }
    }
  }

  .ShopCategories__title {
    width: 100%;
    background-color: transparent;
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    max-height: 210px;
    z-index: 1;
    // max-height: 305px;
    overflow-y: auto;
    //padding: 5px 5px 0;

    //background-color: #eee;

    &::-webkit-scrollbar-track {
      margin-bottom: 10px;
      margin-top: 10px;
      right: 10px;
      padding-right: 10px;
    }

    &[data-opened="true"] {
      border-bottom-left-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
    }
    &_list {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(3, 3fr);
    }
  }

  // &__dropdown_list {
  // }

  &__dropdown_list_item {
    position: relative;
    // width: 100%;
    //min-height: 33px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 7px 5px;
    cursor: pointer;
    font-size: 15px;
    line-height: em(15px, 14px);
    color: #000000;
    border: 1px solid $border-color;
    border-radius: 3px;
    justify-content: center;
    white-space: nowrap;

    &:hover {
      //background-color: #eeeeee;
      color: lighten(#000, 2%);
      opacity: 0.7;
    }

    &[data-selected="true"] {
      color: $primary;
      border-color: $primary;
      font-weight: 600;

      span.ShopSelect__dropdown_list_item-checked {
        display: block;
      }

      .ShopSelect__dropdown_list_item_check {
        display: block;
      }
      small {
        color: #fff !important;
      }
    }

    &_check {
      display: none;
      position: absolute;
      right: 10px;
      color: $primary;
    }
  }
}
