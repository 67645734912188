@import "src/styles/lib";

.ConfirmationModal {
  &.modal-dialog {
    max-width: 620px;
    top: 50%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-content {
    border: 0;
  }
  .modal-header {
    padding: 0;
    height: 0;
    border: 0;
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 3;
      span {
        font-size: 42px;
        line-height: 0.5em;
        color: #000;
        text-shadow: none;
        opacity: 1;
      }
    }
  }
  h2 {
    color: #000000;
    display: block;
    font-size: 32px;
    line-height: 1.3em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    @include mobile {
      font-size: 20px;
    }
  }
  &__form {
    margin-top: 30px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: relative;

    @include mobile {
      width: 100%;
    }

    input {
      width: calc(100% - 126px);
      max-width: 500px;
      border-radius: 1px;
      min-height: 60px;
      background-color: #fff;
      border: 1px solid #fff;
    }

    button {
      padding-right: 30px;
      padding-left: 30px;
      border-radius: 0;
      min-width: 100px;
    }
    .invalid-feedback {
      position: absolute;
      bottom: -20px;
      left: 0;
    }
  }
  .modal-body {
    padding: 50px 100px;
    @include mobile {
      padding: 50px 15px;
    }
  }
  &__countDown {
    &_timer {
      display: flex;
      flex-direction: row;
      align-items: center;
      h3 {
        margin-bottom: 0;
      }
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      width: 100%;
      justify-content: center;
      padding: 10px 0;
      margin-bottom: 30px;
    }
    &_coupon {
      display: flex;
      flex-direction: row;
      align-items: center;
      h3 {
        margin-bottom: 0;
      }
      padding: 10px 0;
      border-bottom: 1px solid #ebebeb;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ebebebeb;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_btn {
      margin-top: 10px;
      width: 100%;
    }
    &_footer {
      display: flex;
      column-gap: 10px;
      text-transform: uppercase;
      padding-top: 30px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.modal.show {
  .ConfirmationModal {
    &.modal-dialog {
      transform: translateY(-50%) !important;
    }
  }
}
