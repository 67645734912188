@import "lib";
@import "bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~typeface-open-sans";
@import "./general";

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 14px;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  .Main_content {
    flex: 1;

    @include tablet {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    @include mobile {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &--loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    position: relative;
    max-width: 1170px;
    //padding: 0;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
    color: $black;
    //TODO: this is not good practice
    transition: 400ms ease-in-out;
  }

  ul {
    margin-bottom: 0;
    list-style-type: none;
  }

  button {
    outline: none;
    //border: none;

    &:focus {
      outline: none;
    }
  }

  /* Override Bootstrap */
  .btn.btn-flat {
    height: 47px;
    color: $white;
    display: block;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    min-width: 112px;
    background: $black;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &__center {
      margin: 0 auto;
    }

    @include mobile {
      &.full-mob {
        width: 100%;
        max-width: unset;
      }
    }
  }

  .btn:focus {
    box-shadow: none;
  }

  .mt-heading {
    margin-top: 83px;
    @include tablet {
      margin-top: 30px;
    }

    @include mobile {
      margin-top: 15px;
    }
  }

  .form-group {
    margin-bottom: 30px;

    label {
      display: block;
      font-size: 13px;
      text-align: left;
      font-weight: 700;
      line-height: 18px;
      padding-left: 0;
      margin-bottom: 10px;
      color: black;
      @include mobile {
        margin-bottom: 2px;
      }
    }

    input.form-control {
      display: block;
      height: 44px;
      padding: 0 15px !important;
      border-color: #ddd;
      border-radius: 3px !important;

      &:focus {
        background: transparent;
        border-color: $black;
      }
    }

    select.form-control {
      display: block;
      height: 44px;
      border-radius: 3px !important;
      border: 1px solid #ddd;
      padding: 0 15px !important;

      &:focus {
        background: #fff;
        border-color: black;
      }

      &.white {
        background: $white;
      }
    }

    textarea.form-control {
      display: block;
      height: 245px;
      border-color: 1px solid $gray;
      padding: 20px 30px;
      border-radius: 0;
      &:focus {
        background-color: #fff;
        border-color: $black;
      }
    }

    @include mobile {
      margin-bottom: 15px;
    }

    .invalid-feedback {
      padding-left: 0;
    }
  }

  .form-check {
    margin-bottom: 30px;
    label {
      font-size: 16px;
      line-height: 18px;
      font-weight: normal;
      color: black !important;
    }

    label.form-check-label {
    }

    input.form-check-input {
      border-radius: unset;
      border-color: $gray;
      width: 15px;
      height: 14px;
      margin-right: 10px;
    }
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: $form-feedback-invalid-color !important;
  }
  .form-control:focus {
    border-color: $gray;
    background-color: $gray;
    box-shadow: unset !important;
  }

  //TODO: fix this shit
  .Login,
  .ResetPassword,
  .Register,
  .ForgotPassword {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.CartContext__loading-place-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 101;
  height: 100vh;
}

.animation-enter {
  opacity: 0;
  transform: translateY(-10%);
  transition: all 0.3s;
}
.animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s;
}
.animation-exit {
  opacity: 1;
  transition: all 0.3s;
}
.animation-exit-active {
  opacity: 0;
  transform: translateY(-10%);
  transition: all 0.3s;
}
