@import "src/styles/lib";

.Menu {
  z-index: 100;
  position: relative;

  display: inline-block;
  &--loading {
    width: 40%;
  }
  @include tablet {
    display: none;
  }

  a {
    transition: none !important;
  }

  &-items {
    display: flex;
    align-content: center;
  }

  &__item {
    transition: all ease-in-out 2ms;
    display: flex;
    align-items: center;

    &:hover {
      .Menu-dropdown {
        display: block;
      }
      cursor: unset !important;
    }

    &:first-child {
      margin-left: 0;
    }
    font-size: 16px;
    font-weight: 700;
    color: black;
    margin: 1.1em 1em;
    svg {
      margin-left: 10px;
    }
    &_inner {
      padding: 15px 0;
    }
    &:hover {
      span {
        color: $primary !important;
        transition: all ease-in-out 2ms;
      }
    }
    &--title {
      &:hover {
        cursor: pointer;
        svg {
          fill: $primary;
        }
      }
    }
  }

  &__item--active {
    color: $primary !important;
    svg {
      fill: $primary;
    }
  }

  &-dropdown {
    min-height: 250px;
    position: absolute;
    // width: 100%;
    z-index: 2;
    left: 0;
    top: 58px;
    background: white;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 30px 45px 30px 45px;
    display: none;
    white-space: nowrap;

    &__list {
      display: flex;
    }

    &__list-item {
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      &-child {
        display: flex;
        align-items: center;
        padding: 9px 0;
        color: #626262 !important;
        font-size: 14px;
        font-weight: 400;
        svg {
          margin-left: 0;
        }
        &:hover {
          color: $primary !important;
          svg {
            fill: $primary;
          }
        }
        &--active {
          color: $primary !important;
          svg {
            fill: $primary;
          }
        }
      }
    }

    &__list-item-title {
      margin-bottom: 15px;
      color: #252525 !important;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 1px solid #efefef;
      padding-bottom: 10px;
      &:hover {
        cursor: unset;
      }
    }
  }
}
