@import "../../styles/lib";

.PriceRange {
  margin: 0 0 0 7px;
  margin-bottom: 50px;
  &__min-max {
    display: flex;
    // justify-content: space-between;
    margin-top: 10px;
    span {
      color: #909598;
      font-size: 14px;
      margin-right: 4px;
    }
    &-wrapper {
      display: flex;
      span {
        margin-right: 4px;
        font-size: 14px;
        color: $primary;
      }
    }
  }
  @include tablet {
    margin: 0 7px;
    margin-bottom: 30px;
  }
}
