@import "./src/styles/lib";

.Container {
  margin: 0 auto;
  max-width: 1650px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @include mobile {
    width: 100%;
    padding: 0;
  }

  @include tablet {
    width: 100%;
    padding: 0;
    max-width: none;
    margin: 0;
  }
}
