@import "./../../../styles/_lib.scss";

.ShopAttributes {
  margin-bottom: 30px;
  label {
    cursor: pointer;
  }

  small {
    font-size: 11px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
  }

  .ShopSelect__dropdown_list {
    max-height: 400px;
    overflow: auto;
  }

  .ShopSelect__dropdown_list_item_check {
    display: none !important;
  }

  &__title {
    display: block;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 7px;
    color: lighten($black, 72.5%);
  }

  &__color {
    //width: 40px;
    //height: 15px;
    //cursor: pointer;
    //border: 1px solid $border-color;
    padding: 4px;
    border-radius: 3px;
    cursor: pointer;
    //border: 1px solid $border-color;
    position: relative;

    //> div {
    //  width: 80%;
    //  height: 80%;
    //  margin-right: 0 !important;
    //  position: absolute;
    //  left: 50%;
    //  top: 50%;
    //  transform: translate(-50%, -50%);
    //}
  }
}
