@import "../../../styles/lib";

.Search {
  position: relative;
  z-index: 101;
  max-width: 724px;

  @media (min-width: 993px) and (max-width: 1150px) {
    display: none;
  }

  @include tablet {
    display: none;
    max-width: unset;
  }

  .input-group-text {
    background-color: white;
    border-right: none !important;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    svg {
      color: $primary;
    }
  }

  &__input {
    border-radius: none !important;
  }

  .input-group {
    width: 100%;
  }
  .form-control {
    padding: 18px 20px;
    border: 1px solid #ebebeb !important;
    border-radius: none !important;
    border-left: none !important;
    border-right: none !important;

    &::placeholder {
      color: $black;
    }

    &:focus {
      box-shadow: none !important;
      background-color: white !important;
      // border-color: $primary !important;
      border-radius: none !important;
    }
  }

  .input-group-append {
    margin-left: 0;
    background-color: white;
    border-left: none !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border: 1px solid #ebebeb;
    padding: 5px;
    border-radius: 3px;
  }

  &__btn_submit {
    border: 1px solid;
    border-radius: 3px;
    background: none;
    background-color: $primary;
    border-color: $primary;
    color: #ffffff;
    cursor: pointer;
    padding: 0 1.41575em;
    text-decoration: none;
    font-weight: 700;
    text-shadow: none;
    &:focus {
      box-shadow: none !important;
    }
  }

  &__spinner {
    position: relative;
    z-index: 10;
    right: 57px;
    top: 8px;
  }

  &__results {
    position: absolute;
    width: 100%;
    min-height: 50px;
    overflow: auto;
    z-index: 100;

    background: white;
    padding: 10px;
    border-radius: 3px;
    top: 56px;
    box-shadow: 1px 12px 11px 0 #00000047;
  }

  ul li:last-child {
    &__results__item {
      border-bottom: none;
    }
  }

  &__results__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    background-color: #fff !important;
    border-radius: 3px;
    border: 1px solid transparent;
    padding: 15px 10px;

    &:hover {
      border: 1px solid $primary;
    }

    @include mobile {
      padding: 10px 5px;
    }

    &__thumb {
      width: 40px;
      height: 40px;
      //border: 1px solid lighten($black, 75%);
      min-width: 40px;
      //background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__name {
      padding-left: 10px;
      font-size: 14px;
      font-weight: 500;
      color: lighten($black, 45%);
    }

    &:hover &__name {
      //color: $primary;
    }
    &:hover &__thumb {
      border-color: $black;
    }
    .ProductPrices h4 {
      margin-bottom: 0;
    }
  }

  &__results__more {
    margin-top: 10px;
    a {
      font-size: 12px;
      font-weight: 600;
      transition: none !important;
      color: lighten($black, 45%) !important;
    }

    a:hover {
      color: $primary !important;
    }
  }

  &--has-results {
    //.form-control {
    //  border-bottom-left-radius: 0 !important;
    //}
    //.btn {
    //  border-bottom-right-radius: 0 !important;
    //}
  }

  &--mobile {
    z-index: 10;
    padding: 30px 10px;
    background-color: $gray;

    .Search__results {
      width: calc(100% - 20px);
      top: 70px;
    }
  }

  .ProductPrices {
    text-align: right !important;
  }
}

@include mobile {
  .Search {
    &--mobile {
      left: 0;
      top: 100%;
      width: 100vw;
      position: relative;
    }
  }
}
