@import "./../../../styles/_lib.scss";

.Quantity {
  max-width: 80px;

  @include mobile {
    margin-top: 0;
  }

  &__input {
    display: flex;
    align-items: center;
    @include mobile {
      //display: none;
    }

    button {
      cursor: pointer;
      width: 24px;
      height: 47px;
      color: $black;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      background: $white;
      text-align: center;
      display: inline-block;
      vertical-align: middle;

      border: 1px $border-color solid !important;
      &:hover {
        background-color: darken(#ffff, 4%);
      }

      &.minus {
        border-right: none !important;
        padding-left: 6px;
      }

      &.plus {
        padding-left: 3px;
        border-left: none !important;
      }
    }

    button:hover {
      background-color: darken($secondary, 3%) !important;
    }

    button:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    button:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    input {
      font-weight: 600;
    }

    input {
      width: 28px;
      height: 47px;
      color: $black;
      outline: none;
      font-size: 14px;
      appearance: unset;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      pointer-events: none;
      display: inline-block;

      border: 1px $border-color solid;
      padding-left: 2px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  //&__select {
  //  display: none;
  //  @include mobile {
  //    display: block;
  //    margin-right: 15px;
  //    select {
  //      color: $black;
  //      font-size: 15px;
  //      background: none;
  //      font-weight: 500;
  //      border: 1px solid #000;
  //      padding: 7px;
  //    }
  //  }
  //}
}
