@import "src/styles/lib";

.ShopFilterActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  @include mobile {
    display: flex;
    margin-bottom: 22px;
    .Select {
      width: 48%;
    }
    select {
      height: 43px;
    }
  }

  &__btn {
    // flex: 1;
    button {
      width: auto;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      @include mobile {
        width: 100%;
      }
    }
    .ShopSelect__label_icon {
      right: 0;
    }
    @include mobile {
      &:nth-child(2) {
        margin-left: 21px;
      }
    }
  }

  &__order_desktop {
    .ShopSelect__dropdown {
      max-width: 250px;
      @include desktop {
        top: 100%;
      }
    }
  }

  &--empty {
    margin-bottom: 0;
  }
}
