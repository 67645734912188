.CheckoutFooter {
  .OrderDetails__total {
    flex-direction: column;
    &__price {
      align-items: flex-start;
      flex-direction: column;
      height: unset;
      padding: 10px;
    }
  }
  label {
    margin-bottom: 0 !important;
  }
}
