.Jobs {
  padding-top: 100px;
  padding-bottom: 100px;
  h4 {
    font-weight: 600;
    margin-bottom: 30px;
  }
  &--file {
    color: #495057 !important;
    height: 44px !important;
    border-color: #ddd !important;
    vertical-align: middle;
    padding: 12px 15px !important;
  }
}
