@import "./../../styles/_lib";

.SlideShow {
  margin-top: 50px;
  position: relative;

  @include mobile {
    font-size: 20px;
    margin-top: 30px;
  }
  @include tablet {
    margin-top: 30px;
    h2 {
      font-size: 20px;
    }
  }
  &-box {
    outline: 0;
    margin-right: 15px;
    margin-left: 15px;
    max-width: 390px;

    img {
      width: 390px;
      min-height: 210px;
    }

    @include mobile {
      width: 160px;
      margin-right: 5px;
      margin-left: 5px;
      img {
        width: 160px;
        height: 199px;
      }
      .Product__image__holder {
        height: 199px;
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    max-height: 210px;

    @include mobile {
      max-height: 114px;
    }

    @include tablet {
      max-height: 171px;
    }
  }

  &__logo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background: rgba($black, 0.5);
    transition: 300ms ease;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }

    &:hover {
      background: rgba($black, 0.7);
      @include mobile {
        background: rgba($black, 0.5);
      }
      @include tablet {
        background: rgba($black, 0.5);
      }
    }
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    color: #fff;
  }

  &__wrapper {
    display: block;
    // background-color: #f3f2ec;
    padding: 10px 0;
    align-items: center;
    z-index: 2;

    &--title {
      padding-bottom: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include tablet {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include mobile {
        padding-left: 20px;
        padding-right: 20px;
        flex-wrap: wrap;
        margin: 0 -15px;
      }

      &-arrow {
        transition: 0.25s ease;
        cursor: pointer;
        &:first-child {
          margin-right: 20px;
        }
        &:hover {
          transition: 0.25s ease;

          g {
            fill: #ffffff;
          }
          path {
            fill: #000000;
          }
        }
      }
    }

    @include tablet {
      padding: 10px 0 0 0;
      margin: 0 -15px;
    }
    @include mobile {
      padding: 10px 0 0 0;
      margin: 0 -15px;
    }

    .Product__brand-name {
      background-color: transparent;
    }
  }
  /* smaller, dark, rounded square */
  .flickity-button {
    transition: all 0.3s;
  }
  .flickity-button:hover {
    transition: all 0.3s;
  }

  .flickity-prev-next-button {
    width: 76px;
    height: 76px;
    border-radius: 0;
    background-color: transparent;
    @include mobile {
      display: none;
    }
  }
  /* icon color */
  .flickity-button-icon {
    fill: black;
  }
  /* position outside */
  .flickity-prev-next-button.previous {
    left: 0;
    z-index: 2;
  }
  .flickity-prev-next-button.next {
    right: 0;
    z-index: 2;
  }
}
