@import "./../../../styles/_lib.scss";

.ProductPricesTemporary {
  color: $black;
  text-align: left;
  h4 {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    @include mobile {
      font-size: 12px;
    }
  }

  &.sale {
    h4 {
      margin: 0;
      &.regular {
        text-decoration-line: line-through;
        font-weight: normal;
        margin-right: 8px;
        color: $primary;
      }

      &.sale {
        // color: red;
      }
    }
  }
}
